import { LoadState } from '@commonTypes/reactTypes';
import useIsMounted from '@hooks/useIsMounted';
import { useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';

/*
    This hook returns data for user by uid.

    Usage: 

    const { dataLoadState, setuid, userData } = useUser(initialUserData);
    setuid(uid);
*/
export const useUser = (initialValue: firebase.firestore.DocumentData = {}): {
  userData: firebase.firestore.DocumentData | {},
  dataLoadState: LoadState,
  setuid: React.Dispatch<React.SetStateAction<string>>,
} => {
  const [uid, setuid] = useState('');
  const [dataLoadState, setDataLoadState] = useState(LoadState.loading);
  const [userData, setUserData] = useState(initialValue);
  const firestore = useFirestore();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (uid) {
      loadUserData();
      listenForUserChange();
    }
  }, [uid]);

  const loadUserData = () => {
    setDataLoadState(LoadState.loading);
    firestore.collection('users').doc(uid).get().then(snap => {
      const data = snap.data();
      if (data == undefined){
        return;
      }
      setUserData(data);
      if (isMounted()) {
        setDataLoadState(LoadState.loaded);
      }
    }).catch(err => {
      console.error(err);
      if (isMounted()) {
        setDataLoadState(LoadState.error);
      }
    });
  };

  const listenForUserChange = () => {
    const userRef = firestore.collection('users').doc(uid);
    userRef.onSnapshot(snap => {
      let snapData = snap.data();
      if (snapData && isMounted()) {
        setUserData(snapData);
      }
    });
  };

  return {dataLoadState, setuid, userData};
};