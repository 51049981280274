/**                                                                         
 _|_|_|_|                              _|_|_|_|_|  _|    _|      _|            
 _|        _|  _|_|    _|_|      _|_|      _|          _|_|_|_|  _|    _|_|    
 _|_|_|    _|_|      _|_|_|_|  _|_|_|_|    _|      _|    _|      _|  _|_|_|_|  
 _|        _|        _|        _|          _|      _|    _|      _|  _|        
 _|        _|          _|_|_|    _|_|_|    _|      _|      _|_|  _|    _|_|_|  
 * FreeTitle Dev
 * Author: Craig P.
**/

import {LoadState} from '@commonTypes/reactTypes';
import {FirebaseFirestore} from '@firebase/firestore-types';
import useCollection, {CollectionContext, RankRule} from '@src/hooks/useCollection';
import { Converter } from '@src/schema/createConverter';
import React from 'react';

interface ChildrenProps {
  children: JSX.Element[] | JSX.Element,
}



/**
 * Description: 
 * 
 * This function dynamically creates a react context with lazyload built in. 
 * 
 * Usage: 
 * 
 * Check out @contexts/BlogsCollectionContext.tsx
 * 
 * Limitation: 
 * 
 * This function has very limited expandability. 
 * For example, the context supports loading a limited number of documents at a time, 
 * and upon calling another batch, loads the next batch of documents.
 * 
 * Six actions are supported for dispatch:
 * refresh: Using current rankBy and labels, get the first batch and replace all loaded documents
 * update: Using current rankBy and labels, update all existing loaded documents
 * getNext: get the next batch of documents and concat to loaded ones
 * setRankBy: change rankBy rules and refresh
 * setLabels: change labels and refresh
 * 
 * It does not support pagination by page number currently, but we may implement server-side implementation 
 * Server-side implementation reference: https://fireship.io/lessons/firestore-pagination-guide/
 * This implementation does not subscribe to live collection change, but you can call getBatch(true) for refresh. 
 */
function createCollectionContext<T = firebase.firestore.DocumentData>(props: {
  batchSize: number,
  getCollectionRef: (firestore: FirebaseFirestore) => firebase.firestore.CollectionReference<T>,
  rankRule: RankRule,
}): [
  React.Context<CollectionContext<T>>,
  (props: ChildrenProps) => JSX.Element,
 ] {
  const {
    getCollectionRef,
    batchSize,
    rankRule,
  } = props;

  const defaultValue: CollectionContext<T> = [{
    currentSize: 0,
    dataLoadState: LoadState.loading,
    documents: [],
    isEnded: false,
    labels: [],
    rankBy: rankRule,
  }, () => {}];

  const CollectionContext = React.createContext<CollectionContext<T>>(defaultValue); 

  const CollectionProvider = (props: ChildrenProps) => {

    const [state, dispatch] = useCollection({
      batchSize,
      getCollectionRef,
      rankRule,
    });
  
    return (
      <CollectionContext.Provider value={[
        state, dispatch
      ]} >
        {props.children}
      </CollectionContext.Provider>
    );
  };

  return [ CollectionContext, CollectionProvider ];
}

export default createCollectionContext;