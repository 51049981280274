import React from 'react';

/**
 * 
 * Description: 
 * 
 * If you see error: Warning: 
 * Can't perform a React state update on an unmounted component. 
 * This is a no-op, but it indicates a memory leak in your application. 
 * To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
 * 
 * Then use this to check if the component is still mounted before calling setState. 
 * 
 * Usage: 
 * 
  const [data, setData] = React.useState(null)
  const isMounted = useIsMounted()
  React.useEffect(() => {
    fetchData().then((result) => {
      if (isMounted()) {
        setData(result)
      }
    })
  }, [])

  Source: 

  https://github.com/webNeat/react-tidy/tree/master/src/useIsMounted
 */
const useIsMounted = () => {
  const ref = React.useRef(true);
  React.useEffect(() => {
    return () => {
      ref.current = false;
    };
  }, []);
  return React.useCallback(() => ref.current, []);
};

export default useIsMounted;