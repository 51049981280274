import React, { useState } from 'react';

const useProject = () => {
  const [env, setEnv] = useState('freetitle');
  const toggleProject = () => {
    if (env === 'freetitle') {
      setEnv('freetitletest');
    } else {
      setEnv('freetitle');
    }
  };

  return [env, toggleProject];
};

export default useProject;