import createDocumentContext from '@contexts/createDocumentContext';
import {useContext} from 'react';

/**
 * This caches last read blog as a global variable. 
 * So that when the user navigates away from the blog and navigate back, the content and scroll height can be restored. 
 * 
 * Usage: 
 * import {BlogDocumentProvider} from '@contexts/BlogDocumentContext';
 * const App = () => (
 *  <BlogDocumentProvider>
      {props.children}
    </BlogDocumentProvider>
 * )
 * 
 * import {BlogDocumentContext} from '@contexts/BlogDocumentContext';
 * import {useContext} from 'react';
 * const blogContext = useContext(BlogDocumentContext);
 * const {dataLoadState, documentData, documentRef, setDocumentRef} = blogContext;
 */
export const [BlogDocumentContext, BlogDocumentProvider] = createDocumentContext();
export const useBlogDocumentContext = () => {
  return useContext(BlogDocumentContext);
};