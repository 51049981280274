/**                                                                         
 _|_|_|_|                              _|_|_|_|_|  _|    _|      _|            
 _|        _|  _|_|    _|_|      _|_|      _|          _|_|_|_|  _|    _|_|    
 _|_|_|    _|_|      _|_|_|_|  _|_|_|_|    _|      _|    _|      _|  _|_|_|_|  
 _|        _|        _|        _|          _|      _|    _|      _|  _|        
 _|        _|          _|_|_|    _|_|_|    _|      _|      _|_|  _|    _|_|_|  
 * FreeTitle Dev
 * Author: Craig P.
 */
import React from 'react';

import ErrorPage from './ErrorPage';

interface Props { }

type State = {
  error: Error | null,
  errorInfo: {
    componentStack: string,
  } | null,
}

/**
 * Description: 
 * 
 * Wrap components within this component as children, 
 * and any error triggered in any child will be caught by this component, 
 * and display the error page instead of crashing the app.
 * 
 * Usage:
 * 
 * \<FTErrorBoundary\>\<PageThatHasError \/\>\</FTErrorBoundary\>
 */
class FTErrorBoundary extends React.Component<{}, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // You can also log error messages to an error reporting service here
    console.error('Error caught: ', error);
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (<ErrorPage />);
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default FTErrorBoundary;