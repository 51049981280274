import React, { useEffect, useState } from 'react';
import {
  useFirestore,
} from 'react-redux-firebase';

const MissionsContext = React.createContext({});

const MissionsProvider = (props) => {
  const [missions, setmissions] = useState([]);
  const [loading, setloading] = useState(false);
  const [last, setlast] = useState(null); // save the last loaded mission so next load start after it
  const [ended, setended] = useState(false); // hide 'load more' button when new missions is empty
  const [rankBy, setrankBy] = useState('lastUpdated');
  const [mission, setmission] = useState(null); // cache latest opened mission, gets updated by missiondetail's getmission
  const [labels, setlabels] = useState([]);
  const BATCH_SIZE = 9;

  const firestore = useFirestore();

  useEffect(() => {
    setmissions([]);
    setlast(null);
    getMissions();
  }, [rankBy, labels]);

  const getFromFirestore = (action) => {
    let ref = firestore.collection('missions');
    let missionsRef = null;
    if (labels && labels.length > 0) {
      ref = ref.where('labels', 'array-contains-any', labels);
    }
    if (last && action != 'refresh') {
      if (rankBy == 'lastUpdated') {
        missionsRef = ref.orderBy('lastUpdated', 'desc').orderBy('likes', 'desc').startAfter(last.data().lastUpdated, last.data().likes).limit(BATCH_SIZE);
      } else if (rankBy == 'likes') {
        missionsRef = ref.orderBy('likes', 'desc').orderBy('lastUpdated', 'desc').startAfter(last.data().likes, last.data().lastUpdated).limit(BATCH_SIZE);
      }
    } else {
      // default by likes and break tie with lastUpdated
      if (rankBy == 'lastUpdated') {
        missionsRef = ref.orderBy('lastUpdated', 'desc').orderBy('likes', 'desc').limit(BATCH_SIZE);
      } else if (rankBy == 'likes') {
        missionsRef = ref.orderBy('likes', 'desc').orderBy('lastUpdated', 'desc').limit(BATCH_SIZE);

      }
    }
    return new Promise(resolve => {
      missionsRef.get().then(snapshot => {
        let loadedMissions = [];
        snapshot.forEach(doc => {
          loadedMissions.push({
            content: doc.data(),
            id: doc.id
          });
        });
        setlast(snapshot.docs[snapshot.docs.length - 1]);
        resolve(loadedMissions);
      });

    });
  };

  const getMissions = async (action) => {
    setloading(true);
    if (action == 'refresh') {
      const newMissions = await getFromFirestore('refresh');
      if (newMissions.length == 0) {
        setended(true);
      } else {
        setended(false);
      }
      setmissions(newMissions);
    } else {
      const newMissions = await getFromFirestore();
      if (newMissions.length == 0) {
        setended(true);
      } else {
        setended(false);
      }
      setmissions(missions.concat(newMissions));
    }
    setloading(false);
  };

  return (
    <MissionsContext.Provider value={{
      ended: ended,
      getMissions: getMissions,
      labels: labels,
      loading: loading,
      mission: mission,
      missions: missions,
      rankBy: rankBy,
      setlabels: setlabels,
      setlast: setlast,
      setmission: setmission,
      setmissions: setmissions,
      setrankBy: setrankBy,
    }} >
      {props.children}
    </MissionsContext.Provider>
  );
};

export { MissionsProvider, MissionsContext };