export type ChildrenProps = {
  children: JSX.Element[] | JSX.Element,
}

export enum LoadState {
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
}

export type Entries = {
  [key: string]: any
}