/**                                                                         
 _|_|_|_|                              _|_|_|_|_|  _|    _|      _|            
 _|        _|  _|_|    _|_|      _|_|      _|          _|_|_|_|  _|    _|_|    
 _|_|_|    _|_|      _|_|_|_|  _|_|_|_|    _|      _|    _|      _|  _|_|_|_|  
 _|        _|        _|        _|          _|      _|    _|      _|  _|        
 _|        _|          _|_|_|    _|_|_|    _|      _|      _|_|  _|    _|_|_|  
 * FreeTitle Dev
 * Author: Craig P.
 */
import React from 'react';

interface Props {
  content?: string | JSX.Element,
  style?: object,
}

/**
 * Description: 
 * 
 * Shows an error message. Supports custom styles.
 * 
 * Usage: 
 * @param content optional content, can be string or React element. 
 * @param style optional style
 * 
 * \<ErrorPage \/\>
 * or \<ErrorPage content="Customized error message"\/\>
 * or \<ErrorPage content={\<p\>Error Component\<\/p\>}\/\>
 */
const ErrorPage = (props: Props) => {
  const content = props.content ?? 'Something went wrong';
  const inputStyle = props.style ?? {};
  const defaultStyle = { display: 'flex', justifyContent: 'center' };
  const style = Object.assign(defaultStyle, inputStyle);
  return (<div style={style}>{content}</div>);
};

export default ErrorPage;