/**                                                                         
 _|_|_|_|                              _|_|_|_|_|  _|    _|      _|            
 _|        _|  _|_|    _|_|      _|_|      _|          _|_|_|_|  _|    _|_|    
 _|_|_|    _|_|      _|_|_|_|  _|_|_|_|    _|      _|    _|      _|  _|_|_|_|  
 _|        _|        _|        _|          _|      _|    _|      _|  _|        
 _|        _|          _|_|_|    _|_|_|    _|      _|      _|_|  _|    _|_|_|  
 * FreeTitle Dev
 * Author: Craig P.
 **/

import { LoadState } from '@commonTypes/reactTypes';
import {FirebaseFirestore} from '@firebase/firestore-types';
import { useEffect, useReducer } from 'react';
import { useFirestore } from 'react-redux-firebase';

export type OrderBy = {
  field: 'likes' | 'time' | 'lastUpdated',
  order: 'asc' | 'desc'
}

export type RankRule = {
  main: OrderBy, 
  secondary: OrderBy
}

type TData<T> = {
  content: T,
  id: string
}

export type CollectionState<T = firebase.firestore.DocumentData> = {
  dataLoadState: LoadState,
  documents: TData<T>[],
  last?: firebase.firestore.QueryDocumentSnapshot<T> | undefined,
  isEnded: boolean,
  labels: string[],
  rankBy: RankRule,
  currentSize: number
};

export type CollectionContext<T> = [ CollectionState<T>, React.Dispatch<CollectionAction> ];

enum fetchType {
  refresh = 'refresh',
  update = 'update',
  next = 'next'
}

type _Action<T> =  
| { type: 'loading' }
| { type: 'refreshed', documents: TData<T>[] }
| { type: 'updated', documents: TData<T>[] }
| { type: 'gotNext', documents: TData<T>[] }
| { type: 'error' }
| { type: 'setLast', last: firebase.firestore.QueryDocumentSnapshot<T> | undefined }
| { type: 'setRankBy', rankBy: RankRule }
| { type: 'setLabels', labels: string[] };

export type CollectionAction = 
| {type: 'refresh'}
| {type: 'update'}
| {type: 'getNext'}
| {type: 'setRankBy', rankBy: RankRule}
| {type: 'setLabels', labels: string[]}

type CollectionReducer<T> = (prevState: CollectionState<T>, action: _Action<T>) => CollectionState<T>;

/**
 * This reducer is for internal use
 * @param rankBy 
 */
function useCollectionReducer<T = firebase.firestore.DocumentData>(rankBy: RankRule): [CollectionState<T>, React.Dispatch<_Action<T>>] {
  const defaultValue: CollectionState<T> = {
    currentSize: 0,
    dataLoadState: LoadState.loading,
    documents: [],
    isEnded: false,
    labels: [],
    rankBy
  };

  function reducer<T>(prevState: CollectionState<T>, action: _Action<T>): CollectionState<T> {
    switch(action.type){
      case 'loading': 
        return {
          ...prevState, 
          dataLoadState: LoadState.loading,
        };
      case 'updated':
      case 'refreshed':
        return {
          ...prevState,
          currentSize: action.documents.length,
          dataLoadState: LoadState.loaded,
          documents: action.documents,
          isEnded: action.documents.length === 0
        };
      case 'error':
        return {
          ...prevState,
          dataLoadState: LoadState.error,
        };
      case 'gotNext':
        return {
          ...prevState,
          currentSize: prevState.currentSize + action.documents.length,
          dataLoadState: LoadState.loaded,
          documents: [...prevState.documents, ...action.documents],
          isEnded: action.documents.length === 0
        };
      case 'setLast':
        return {
          ...prevState, 
          last: action.last ?? prevState.last
        };
      case 'setLabels':
        return {
          ...prevState, 
          labels: action.labels,
        };
      case 'setRankBy':
        return {
          ...prevState, 
          rankBy: action.rankBy,
        };
      default:
        console.error('invalid action type');
        return prevState;
    }
  }

  const [state, dispatch] = useReducer<CollectionReducer<T>>(reducer, defaultValue);
  return [state, dispatch];
}

/**
 * Description: 
 * 
 */
export default function useCollection<T = firebase.firestore.DocumentData>(props: {
  batchSize: number,
  getCollectionRef: (firestore: FirebaseFirestore) => firebase.firestore.CollectionReference<T>,
  rankRule: RankRule,
}): CollectionContext<T> {

  const firestore = useFirestore();

  const {
    getCollectionRef,
    batchSize,
    rankRule,
  } = props;

  const [state, _dispatch] = useCollectionReducer<T>(rankRule);

  const getFromFirestore = async (type: fetchType) => {
    const {currentSize, labels, last, rankBy} = state;
    // build query
    let query: firebase.firestore.Query<T> = getCollectionRef(firestore);
    if (labels && labels.length > 0) {
      query = query.where('labels', 'array-contains-any', labels);
    }
    switch(type){
      case fetchType.next:
        if (last == undefined) {
          throw new Error('Can\'t get next documents if there is no recorded last document');
        }
        query = query.orderBy(rankBy.main.field, rankBy.main.order)
          .orderBy(rankBy.secondary.field, rankBy.secondary.order)
          .startAfter(last)
          .limit(batchSize);
        break;
      case fetchType.refresh:
        query = query.orderBy(rankBy.main.field, rankBy.main.order)
          .orderBy(rankBy.secondary.field, rankBy.secondary.order)
          .limit(batchSize);
        break;
      case fetchType.update:
        query = query.orderBy(rankBy.main.field, rankBy.main.order)
          .orderBy(rankBy.secondary.field, rankBy.secondary.order)
          .limit(currentSize);
        break;
      default:
        throw new Error('no fetch type specified');
    }

    // start query
    const loadedDocuments: TData<T>[] | null | undefined = await new Promise((resolve, reject) => {
      query.get().then(snapshot => {
        const loaded: TData<T>[] = [];
        snapshot.forEach(doc => {
          loaded.push({
            content: doc.data(),
            id: doc.id
          });
        });
        _dispatch({last: snapshot.docs[snapshot.docs.length - 1], type: 'setLast'});
        resolve(loaded);
      }).catch(reject);
    });
    return loadedDocuments;
  };

  /**
   * This is the dispatch for external use
   * @param action CollectionActino
   */
  const dispatch = async (action: CollectionAction) => {
    let newDocuments;
    switch(action.type){
      case 'refresh':
        _dispatch({type: LoadState.loading});
        newDocuments = await getFromFirestore(fetchType.refresh);
        if (newDocuments == undefined){
          _dispatch({type: LoadState.error});
          return;
        }
        _dispatch({documents: newDocuments, type: 'refreshed'});
        break;
      case 'update':
        newDocuments = await getFromFirestore(fetchType.update);
        if (newDocuments == undefined){
          _dispatch({type: LoadState.error});
          return;
        }
        _dispatch({documents: newDocuments, type: 'updated'});
        break;
      case 'getNext':
        if (state.isEnded){
          return;
        }
        newDocuments = await getFromFirestore(fetchType.next);
        if (newDocuments == undefined){
          _dispatch({type: LoadState.error});
          return;
        }
        _dispatch({documents: newDocuments, type: 'gotNext'});
        break;
      case 'setLabels':
        _dispatch({labels: action.labels, type: 'setLabels'});
        break;
      case 'setRankBy':
        _dispatch({rankBy: action.rankBy, type: 'setRankBy'});
        break;
      default: 
        return;
    }
  };

  useEffect(() => {
    dispatch({type: 'refresh'});
  }, [state.labels, state.rankBy]);

  return [state, dispatch];
}