import createCollectionContext from '@contexts/createCollectionContext';
import {useContext} from 'react';

export const [BlogsCollectionContext, BlogsCollectionProvider] = createCollectionContext({
  batchSize: 10,
  getCollectionRef: firestore => firestore.collection('blogs'),
  rankRule: {
    main: {
      field: 'time',
      order: 'desc',
    },
    secondary: {
      field: 'likes',
      order: 'desc',
    }
  }
});

export const useBlogsCollectionContext = () => {
  return useContext(BlogsCollectionContext);
};