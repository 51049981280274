import useOutsideClick from '@hooks/useOutsideClick';
import { IconButton } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import algoliasearch from 'algoliasearch';
import React, { useEffect, useRef, useState } from 'react';

const searchClient = algoliasearch(
  'IYDL1QZXLL',
  '54ecc792d0ef66f0a4360e4afc0bbe16'
);

const useStyles = makeStyles(theme => ({
  inputInput: {
    borderBottom: '1px solid ' + (theme.palette.type == 'dark' ? '#fff' : '#000'),
    margin: '0.5rem',
    width: '100%',
  },
  searchIcon: {
    '& button': {
      width: '50px',
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: 'fit-content',
    zIndex: 10
  },
  searchList: {
    '& li': {
      '&:hover': {
        color: theme.palette.primary.main,
      },
      cursor: 'pointer',
      listStyleType: 'none'
    },
    backgroundColor: theme.palette.type == 'dark' ? '#333' : '#fff',
    borderRadius: '10px',
    boxShadow: '3px 2px 4px 0px black',
    left: '5%',
    padding: '0.5rem',
    position: 'absolute',
    width: '90%',
    zIndex: 999,
  },
}));

export default function Search(props) {

  const classes = useStyles();
  const getBlog = props.getBlog;
  const getUser = props.getUser;
  const getMission = props.getMission;
  const [missionRes, setmissionRes] = useState([]);
  const [userRes, setuserRes] = useState([]);
  const [blogRes, setblogRes] = useState([]);
  const [currentRefinement, setcurrentRefinement] = useState(null);
  const [open, setopen] = useState(false);

  const ref = useRef();

  useOutsideClick(ref, () => {
    setopen(false);
  });

  const refine = (value) => {
    setcurrentRefinement(value);
  };

  const search = (currentRefinement) => {
    const blogQuery = {
      indexName: 'blogs',
      params: {
        hitsPerPage: 5
      },
      query: currentRefinement
    };
    const missionQuery = {
      indexName: 'missions',
      params: {
        hitsPerPage: 5
      },
      query: currentRefinement
    };
    const userQuery = {
      indexName: 'users',
      params: {
        hitsPerPage: 5
      },
      query: currentRefinement
    };
    searchClient.multipleQueries([missionQuery]).then(({ results }) => {
      const hits = results && results[0].hits;
      let resultHits = hits.reduce((prev, result) => {
        if (!result.name && !(result._fieldsProto && result._fieldsProto.name)) {
          // handle exception
          return prev;
        }
        let obj = {
          id: result.objectID,
          name: result.name || (result._fieldsProto.name && result._fieldsProto.name.stringValue),
          type: 'missions',
        };
        return prev.concat([obj]);
      }, []);
      setmissionRes(resultHits);
    });

    searchClient.multipleQueries([userQuery]).then(({ results }) => {
      const hits = results && results[0].hits;
      let resultHits = hits.reduce((prev, result) => {
        if (!result.displayName && !(result._fieldsProto && result._fieldsProto.displayName)) {
          // handle exception
          return prev;
        }
        let obj = {
          id: result.objectID,
          name: result.displayName || (result._fieldsProto.displayName && result._fieldsProto.displayName.stringValue),
          type: 'users',
        };
        return prev.concat([obj]);
      }, []);
      setuserRes(resultHits);
    });
    searchClient.multipleQueries([blogQuery]).then(({ results }) => {
      const hits = results && results[0].hits;
      let resultHits = hits.reduce((prev, result) => {
        if (!result.title && !(result._fieldsProto && result._fieldsProto.title)) {
          // handle exception
          return prev;
        }
        let obj = {
          id: result.objectID,
          name: result.title || (result._fieldsProto.title && result._fieldsProto.title.stringValue),
          type: 'blogs',
        };
        return prev.concat([obj]);
      }, []);
      setblogRes(resultHits);
    });
  };

  useEffect(() => {
    search(currentRefinement);
  }, [currentRefinement]);

  return (
    <div ref={ref}>
      <IconButton className={classes.searchIcon} onClick={() => setopen(true)}>
        <SearchIcon />
      </IconButton>

      {open && (<div className={classes.searchList}>
        <InputBase
          autoFocus
          className={classes.inputInput}
          inputProps={{ 'aria-label': 'search' }}
          placeholder="Search…"
          value={currentRefinement}
          onChange={event => refine(event.currentTarget.value)}
        />

        {missionRes.length > 0 && <b style={{ color: 'grey' }}>mission results: </b>}
        <ul>
          {missionRes.map(res => (
            <li key={res.id} onMouseDown={() => {
              setopen(false);
              getMission(res.id);
            }}>{res.name}</li>
          ))}
        </ul>

        {userRes.length > 0 && <b style={{ color: 'grey' }}>user results: </b>}
        <ul>
          {userRes.map(res => (
            <li key={res.id} onMouseDown={() => {
              setopen(false);
              getUser(res.id);
            }}>{res.name}</li>
          ))}
        </ul>

        {blogRes.length > 0 && <b style={{ color: 'grey' }}>blog results: </b>}
        <ul>
          {blogRes.map(res => (
            <li key={res.id} onMouseDown={() => {
              setopen(false);
              getBlog(res.id);
            }}>{res.name}</li>
          ))}
        </ul>
      </div>)}

    </div>
  );
}
