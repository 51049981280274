// https://code.almeros.com/code-examples/water-effect-canvas/
export function WaterCanvas(width, height, documentElement, waterModel, props) {
  // If a certain property is not set, use a default value
  props = props || {};
  this.backgroundImageUrl = props.backgroundImageUrl || null;
  this.text = props.text || '';
  this.lightRefraction = props.lightRefraction || 9.0;
  this.lightReflection = props.lightReflection || 0.1;
  this.showStats = props.showStats || false;

  this.width = width;
  this.height = height;
  this.documentElement = document.getElementById(documentElement);
  this.waterModel = waterModel;


  this.canvas = document.createElement('canvas');
  this.canvasHelp = document.createElement('canvas');

  if (!this.canvas.getContext || !this.canvasHelp.getContext) {
    alert('You need a browser that supports the HTML5 canvas tag.');
    return; // No point to continue
  }

  this.ctx = this.canvas.getContext('2d');
  this.ctxHelp = this.canvasHelp.getContext('2d');

  this.setSize(width, height);
  this.documentElement.appendChild(this.canvas);




  // Find out the FPS at certain intervals
  this.fps = -1;
  if (this.showStats) {
    this.fpsCounter = 0;
    this.prevMs = 0;

    let self = this;
    setInterval(function () {
      self.findFps();
    }, 1000);
  }


  // Start the animation
  this.drawNextFrame();
}

/**
 * Sets the size of the canvas.
 *
 * @param {Number} width The width in pixels this canvas should be.
 * @param {Number} hight The hight in pixels this canvas should be.
 */
WaterCanvas.prototype.setSize = function (width, height) {
  this.width = width;
  this.height = height;

  this.canvas.setAttribute('width', this.width);
  this.canvas.setAttribute('height', this.height);

  this.canvasHelp.setAttribute('width', this.width);
  this.canvasHelp.setAttribute('height', this.height);
  this.setBackground(this.backgroundImageUrl, this.text);
};

/**
 * Sets the image to perform the water rippling effect on. 
 *
 * Use an image from the same server as where this script lives. This is needed since browsers use the 
 * Same Origin Policy for savety reasons. If an empty URL is given, a standard canvas will be shown.
 *
 * @param {String} backgroundImageUrl (Relative) URL to an image on the same webserver as this script.						
 */
WaterCanvas.prototype.setBackground = function (backgroundImageUrl, text) {
  this.backgroundImageUrl = backgroundImageUrl == '' ? null : backgroundImageUrl;
  this.pixelsIn = null;
  if (this.backgroundImageUrl != null) {

    // Background image loading
    this.backgroundImg = new Image();
    this.backgroundImg.crossOrigin = 'Anonymous';

    let self = this;
    this.backgroundImg.onload = function () {

      // set object fit cover on backgroundImg
      let x = 0;
      let y = 0;
      let w = self.width;
      let h = self.height;

      // default offset is center
      let offsetX = 0.5;
      let offsetY = 0.5;

      // keep bounds [0.0, 1.0]
      if (offsetX < 0) offsetX = 0;
      if (offsetY < 0) offsetY = 0;
      if (offsetX > 1) offsetX = 1;
      if (offsetY > 1) offsetY = 1;

      let iw = self.backgroundImg.width,
        ih = self.backgroundImg.height,
        r = Math.min(w / iw, h / ih),
        nw = iw * r,   // new prop. width
        nh = ih * r,   // new prop. height
        cx, cy, cw, ch, ar = 1;

      // decide which gap to fill    
      if (nw < w) ar = w / nw;
      if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
      nw *= ar;
      nh *= ar;

      // calc source rectangle
      cw = iw / (nw / w);
      ch = ih / (nh / h);

      cx = (iw - cw) * offsetX;
      cy = (ih - ch) * offsetY;

      // make sure source rectangle is valid
      if (cx < 0) cx = 0;
      if (cy < 0) cy = 0;
      if (cw > iw) cw = iw;
      if (ch > ih) ch = ih;

      self.ctxHelp.drawImage(self.backgroundImg, cx, cy, cw, ch, x, y, w, h);

      // Create gradient
      let gradient = self.ctxHelp.createLinearGradient(0, 0, self.width, 0);
      gradient.addColorStop('0', 'lightblue');
      gradient.addColorStop('0.5', 'darkgray');
      gradient.addColorStop('1.0', 'orange');
      // Fill with gradient
      self.ctxHelp.fillStyle = gradient;
      self.ctxHelp.font = '2rem Verdana';
      self.ctxHelp.fillText(text, 40, (self.height / 2));

      // Get the canvas pixel data
      let imgDataIn = self.ctxHelp.getImageData(0, 0, self.width, self.height);
      self.pixelsIn = imgDataIn.data;

      // Also paint it on the output canvas
      self.ctx.putImageData(imgDataIn, 0, 0);
    };
    this.backgroundImg.src = this.backgroundImageUrl;
  }
  else {
    // Create a canvas
    let pointerCanvas = document.createElement('canvas');
    pointerCanvas.setAttribute('width', this.width);
    pointerCanvas.setAttribute('height', this.height);
    let pointerCtx = pointerCanvas.getContext('2d');

    let radgrad = pointerCtx.createRadialGradient(this.width / 2, this.height / 2, 0, this.width / 2, this.height / 2, this.height / 2);
    radgrad.addColorStop(0, '#4af');
    radgrad.addColorStop(1, '#000');

    this.ctxHelp.fillStyle = radgrad;
    this.ctxHelp.fillRect(0, 0, this.width, this.height);

    this.ctxHelp.shadowColor = 'white';
    this.ctxHelp.shadowOffsetX = 0;
    this.ctxHelp.shadowOffsetY = 0;
    this.ctxHelp.shadowBlur = 10;

    this.ctxHelp.textBaseline = 'top';
    this.ctxHelp.font = 'normal 200 45px verdana';
    this.ctxHelp.fillStyle = 'white';
    this.ctxHelp.fillText('Water Canvas', 10, (this.height / 2) - 40);
    this.ctxHelp.font = 'normal 200 12px verdana';
    this.ctxHelp.fillText('Move your mouse over this canvas to move the water.', 10, (this.height / 2) + 10);
    this.ctxHelp.fillText('By Almeros 2010, See http://code.almeros.com', 10, (this.height / 2) + 30);

    // Get the canvas pixel data
    let imgDataIn = this.ctxHelp.getImageData(0, 0, this.width, this.height);
    this.pixelsIn = imgDataIn.data;

    // Also paint it on the output canvas
    this.ctx.putImageData(imgDataIn, 0, 0);

  }
};

/**
 * Renders the next frame and draws it on the canvas. 
 * Also handles calling itself again via requestAnim(ation)Frame.
 *
 * @private
 */
WaterCanvas.prototype.drawNextFrame = function () {
  if (this.pixelsIn == null || !this.waterModel.isEvolving()) {
    // Wait some time and try again
    var self = this;
    setTimeout(function () {
      self.drawNextFrame();
    }, 50);

    // Nothing else to do for now
    return;
  }


  // Make the canvas give us a CanvasDataArray. 
  // Creating an array ourselves is slow!!!
  // https://developer.mozilla.org/en/HTML/Canvas/Pixel_manipulation_with_canvas
  let imgDataOut = this.ctx.getImageData(0, 0, this.width, this.height);
  let pixelsOut = imgDataOut.data;
  let n = pixelsOut.length;
  for (let i = 0; i < n; i += 4) {
    let pixel = i / 4;
    let x = pixel % this.width;
    let y = (pixel - x) / this.width;

    let strength = this.waterModel.getWater(x, y);


    // Refraction of light in water
    let refraction = Math.round(strength * this.lightRefraction);

    let xPix = x + refraction;
    let yPix = y + refraction;

    if (xPix < 0) xPix = 0;
    if (yPix < 0) yPix = 0;
    if (xPix > this.width - 1) xPix = this.width - 1;
    if (yPix > this.height - 1) yPix = this.height - 1;



    // Get the pixel from input
    let iPix = ((yPix * this.width) + xPix) * 4;
    let red = this.pixelsIn[iPix];
    let green = this.pixelsIn[iPix + 1];
    let blue = this.pixelsIn[iPix + 2];


    // Set the pixel to output
    strength *= this.lightReflection;
    strength += 1.0;

    pixelsOut[i] = red *= strength;
    pixelsOut[i + 1] = green *= strength;
    pixelsOut[i + 2] = blue *= strength;
    pixelsOut[i + 3] = 255; // alpha 
  }

  this.ctx.putImageData(imgDataOut, 0, 0);



  if (this.showStats) {
    this.fpsCounter++;


    this.ctx.textBaseline = 'top';
    this.ctx.font = 'normal 200 10px arial';

    this.ctx.fillStyle = 'white';
    this.ctx.fillText('FPS Canvas: ' + this.getFps(), 10, 10);
    this.ctx.fillText('FPS Water: ' + this.waterModel.getFps(), 10, 20);

    this.ctx.shadowColor = 'black';
    this.ctx.shadowOffsetX = 0;
    this.ctx.shadowOffsetY = 0;
    this.ctx.shadowBlur = 2;
  }



  // Make the browser call this function at a new render frame
  self = this; // For referencing 'this' in internal eventListeners
  window.requestAnimFrame(function () {
    self.drawNextFrame();
  }, this.canvas);

};

/**
 * Determine the Frames Per Second. 
 * Called at regular intervals by an internal setInterval.
 *
 * @private
 */
WaterCanvas.prototype.findFps = function () {
  if (!this.showStats)
    return;

  let nowMs = new Date().getTime();
  let diffMs = nowMs - this.prevMs;

  this.fps = Math.round(((this.fpsCounter * 1000) / diffMs) * 10.0) / 10.0;

  this.prevMs = nowMs;
  this.fpsCounter = 0;
};

/**
 * @returns The Frames Per Second that was last rendered.
 */
WaterCanvas.prototype.getFps = function () {
  return this.fps;
};

/**
 * Sets the a amount of refraction of light through the water.
 *
 * @param {Number} lightRefraction The a amount of refraction of light.
 */
WaterCanvas.prototype.setLightRefraction = function (lightRefraction) {
  this.lightRefraction = lightRefraction;
};

/**
 * Sets the a amount of reflection of light on the water.
 *
 * @param {Number} lightRefraction The a amount of reflection of light.
 */
WaterCanvas.prototype.setLightReflection = function (lightReflection) {
  this.lightReflection = lightReflection;
};





////////////////////////////////////////////////////////////////////////////////
//                              WaterModel object                             //
////////////////////////////////////////////////////////////////////////////////

/**
 * Model object that is responsible for holding, manipulating and updating the water ripple data.
 * 
 * @param {Number} width The width in pixels this model should work with.
 * @param {Number} hight The hight in pixels this model should work with.
 * @param {Object} props A key/value object which may contain the following properties:
 * 				resolution:  
 *						Sets the pixelsize to use in the model. The higher hte better the performance, but 
 *						you may want to use interpolation to prefent visible block artifacts.
 *				interpolate: 
 *						When the resolution is set higher then 1.0, you can set this to true to use interpolation.
 *				damping: 
 *						Effectively sets how long water ripples travel.
 *				clipping: 
 *						Multiple waves add up. This may create a wave that's to high or low. The clipping value sets 
 *						the absolute maximum a water pixel value may have in the model.
 *				evolveThreshold: 
 *						To prevent this  script from always taking up CPU cycles, even when no water rippling 
 *						is going on in the model (your website is on a non visible tab) you can set a threshold. When 
 *						no water pixel is above this absolute value, the WaterModel and the WaterCanvas will both stop 
 *						rendering until new waves are created by the user.
 *				maxFps: 
 *						Maximum Frames Per Second; The maximum reachable FPS highly depends on the system and browser 
 *						the water canvas is running on. You can't control that, but you can control the maximum FPS to 
 *						keep systems from overloading, trying to reach the highest FPS.
 *				showStats: 
 *						When set to true, it shows "FPS Water: " plus the current FPS of this model on the canvas.
 *
 * @constructor
 */
export function WaterModel(width, height, props) {
  // If a certain property is not set, use a default value
  props = props || {};
  this.resolution = props.resolution || 2.0;
  this.interpolate = props.interpolate || false;
  this.damping = props.damping || 0.985;
  this.clipping = props.clipping || 5;
  this.maxFps = props.maxFps || 30;
  this.showStats = props.showStats || false;
  this.evolveThreshold = props.evolveThreshold || 0.05;

  this.width = Math.ceil(width / this.resolution);
  this.height = Math.ceil(height / this.resolution);



  // Create water model 2D arrays
  this.resetSizeAndResolution(width, height, this.resolution);

  this.setMaxFps(this.maxFps);

  this.evolving = false; // Holds whether it's needed to render frames



  // Find out the FPS at certain intervals
  this.fps = -1;
  if (this.showStats) {
    this.fpsCounter = 0;
    this.prevMs = 0;

    let self = this;
    setInterval(function () {
      self.findFps();
    }, 1000);
  }
}

/**
 * Gets the (interpolated) water value of an coordinate.
 *
 * @param {Number} x The X position.
 * @param {Number} y The Y position.
 *
 * @returns A float value representing the hight of the water.
 */
WaterModel.prototype.getWater = function (x, y) {
  let xTrans = x / this.resolution;
  let yTrans = y / this.resolution;

  if (!this.interpolate || this.resolution == 1.0) {
    let xF = Math.floor(xTrans);
    let yF = Math.floor(yTrans);

    if (xF > this.width - 1 || yF > this.height - 1)
      return 0.0;

    return this.depthMap1[xF][yF];
  }


  // Else use Bilinear Interpolation
  let xF = Math.floor(xTrans);
  let yF = Math.floor(yTrans);
  let xC = Math.ceil(xTrans);
  let yC = Math.ceil(yTrans);

  if (xC > this.width - 1 || yC > this.height - 1)
    return 0.0;

  // Now get 4 points from the array
  let br = this.depthMap1[xF][yF];
  let bl = this.depthMap1[xC][yF];
  let tr = this.depthMap1[xF][yC];
  let tl = this.depthMap1[xC][yC];

  // http://tech-algorithm.com/articles/bilinear-image-scaling/
  //	D   C
  //	  Y
  //	B	A
  // Y = A(1-w)(1-h) + B(w)(1-h) + C(h)(1-w) + Dwh

  let xChange = xC - xTrans;
  let yChange = yC - yTrans;
  let intpVal =
    tl * (1 - xChange) * (1 - yChange) +
    tr * (xChange) * (1 - yChange) +
    bl * (yChange) * (1 - xChange) +
    br * xChange * yChange;

  return intpVal;
};

/**
 * Sets bilinear interpolation on or off. Interpolation will give a more smooth effect
 * when a higher resolution is used, but needs CPU resources for that.
 *
 * @param {Boolean} interpolate Whether to use interpolation or not
 */
WaterModel.prototype.setInterpolation = function (interpolate) {
  this.interpolate = interpolate;
};

/**
 * Gets the (interpolated) water value of an coordinate.
 *
 * @param {Number} x The X position. The center of where the array2d will be placed.
 * @param {Number} y The Y position. The center of where the array2d will be placed.
 * @param {Number} pressure The factor to multiply the array2d values with while adding the array2d to the model.
 * @param {Array} array2d A 2D array containing float values between -1.0 and 1.0 in a pattern.
 */
WaterModel.prototype.touchWater = function (x, y, pressure, array2d) {
  this.evolving = true;

  x = Math.floor(x / this.resolution);
  y = Math.floor(y / this.resolution);

  // Place the array2d in the center of the mouse position
  if (array2d.length > 4 || array2d[0].length > 4) {
    x -= array2d.length / 2;
    y -= array2d[0].length / 2;
  }

  if (x < 0) x = 0;
  if (y < 0) y = 0;
  if (x > this.width) x = this.width;
  if (y > this.height) y = this.height;

  // Big pixel block
  for (let i = 0; i < array2d.length; i++) {
    for (let j = 0; j < array2d[0].length; j++) {

      if (x + i >= 0 && y + j >= 0 && x + i <= this.width - 1 && y + j <= this.height - 1) {
        this.depthMap1[x + i][y + j] -= array2d[i][j] * pressure;
      }

    }
  }
};

/**
 * Renders the next frame in the model. The water ripples will be evolved one step.
 * Called at regular intervals by an internal setInterval.
 *
 * @private
 */
WaterModel.prototype.renderNextFrame = function () {
  if (!this.evolving)
    return;

  this.evolving = false;

  for (let x = 0; x < this.width; x++) {
    for (let y = 0; y < this.height; y++) {

      // Handle borders correctly
      let val = (x == 0 ? 0 : this.depthMap1[x - 1][y]) +
        (x == this.width - 1 ? 0 : this.depthMap1[x + 1][y]) +
        (y == 0 ? 0 : this.depthMap1[x][y - 1]) +
        (y == this.height - 1 ? 0 : this.depthMap1[x][y + 1]);

      // Damping
      val = ((val / 2.0) - this.depthMap2[x][y]) * this.damping;

      // Clipping prevention
      if (val > this.clipping) val = this.clipping;
      if (val < -this.clipping) val = -this.clipping;

      // Evolve check
      if (Math.abs(val) > this.evolveThreshold)
        this.evolving = true;


      this.depthMap2[x][y] = val;
    }
  }

  // Swap buffer references
  this.swapMap = this.depthMap1;
  this.depthMap1 = this.depthMap2;
  this.depthMap2 = this.swapMap;

  this.fpsCounter++;
};

/**
 * Tells if the WaterModel is currently evolving. When all postions in the model are below a threshold 
 * (evolveThreshold), evolving will be set to false. This saves resources, especially when the canvas 
 * is not visible on screen.
 *
 * @returns A boolean that tells if the WaterModel is currently in evolving state.
 */
WaterModel.prototype.isEvolving = function () {
  return this.evolving;
};

/**
 * Determine the Frames Per Second. 
 * Called at regular intervals by an internal setInterval.
 *
 * @private
 */
WaterModel.prototype.findFps = function () {
  if (!this.showStats)
    return;

  let nowMs = new Date().getTime();
  let diffMs = nowMs - this.prevMs;

  this.fps = Math.round(((this.fpsCounter * 1000) / diffMs) * 10.0) / 10.0;

  this.prevMs = nowMs;
  this.fpsCounter = 0;
};

/**
 * @returns The Frames Per Second that was last rendered.
 */
WaterModel.prototype.getFps = function () {
  return this.fps;
};

/**
 * Sets the maximum frames per second to render. Use this to set a limit and release resources for other processes.
 *
 * @param {Number} maxFps The maximum frames per second to render.
 */
WaterModel.prototype.setMaxFps = function (maxFps) {
  this.maxFps = maxFps;

  clearInterval(this.maxFpsInterval);

  // Updating of the animation
  let self = this; // For referencing 'this' in internal eventListeners	

  if (this.maxFps > 0) {
    this.maxFpsInterval = setInterval(function () {
      self.renderNextFrame();
    }, 1000 / this.maxFps);
  }
};

/**
 * Effectively sets how long water ripples travel.
 *
 * @param {Number} damping The amount of strength to pass on from postion to surrounding positions.
 */
WaterModel.prototype.setDamping = function (damping) {
  this.damping = damping;
};

/**
 * Effectively sets how long water ripples travel.
 * 
 * @param {Number} width The width in pixels this model should work with.
 * @param {Number} hight The hight in pixels this model should work with.
 * @param {Number} resolution The pixel size of a models position. The higher the resolution, the less positions to render, the faster. 
 */
WaterModel.prototype.resetSizeAndResolution = function (width, height, resolution) {
  this.width = Math.ceil(width / resolution);
  this.height = Math.ceil(height / resolution);
  this.resolution = resolution;

  this.depthMap1 = new Array(this.width);
  this.depthMap2 = new Array(this.width);
  for (let x = 0; x < this.width; x++) {
    this.depthMap1[x] = new Array(this.height);
    this.depthMap2[x] = new Array(this.height);

    for (let y = 0; y < this.height; y++) {
      this.depthMap1[x][y] = 0.0;
      this.depthMap2[x][y] = 0.0;
    }
  }
};






////////////////////////////////////////////////////////////////////////////////
//                                 Util functions                             //
////////////////////////////////////////////////////////////////////////////////

/**
 * A class to mimic rain on the given waterModel with raindrop2dArray's as raindrops.
 */
export function RainMaker(width, height, waterModel, raindrop2dArray) {
  this.width = width;
  this.height = height;
  this.waterModel = waterModel;
  this.raindrop2dArray = raindrop2dArray;

  this.rainMinPressure = 1;
  this.rainMaxPressure = 3;
}

RainMaker.prototype.raindrop = function () {
  let x = Math.floor(Math.random() * this.width);
  let y = Math.floor(Math.random() * this.height);
  this.waterModel.touchWater(x, y, this.rainMinPressure + Math.random() * this.rainMaxPressure, this.raindrop2dArray);
};

RainMaker.prototype.setRaindropsPerSecond = function (rps) {
  this.rps = rps;

  clearInterval(this.rainInterval);

  if (this.rps > 0) {
    let self = this;
    this.rainInterval = setInterval(function () {
      self.raindrop();
    }, 1000 / this.rps);
  }
};

RainMaker.prototype.setRainMinPressure = function (rainMinPressure) {
  this.rainMinPressure = rainMinPressure;
};

RainMaker.prototype.setRainMaxPressure = function (rainMaxPressure) {
  this.rainMaxPressure = rainMaxPressure;
};

/**
 * Enables mouse interactivity by adding event listeners to the given documentElement and
 * using the mouse coordinates to 'touch' the water.
 */
export function enableMouseInteraction(waterModel, documentElement) {
  let mouseDown = false;

  let finger = [
    [0.5, 1.0, 0.5],
    [1.0, 1.0, 1.0],
    [0.5, 1.0, 0.5]
  ];

  let pixel = [
    [0.5, 1.0, 0.5],
    [1.0, 1.0, 1.0],
    [0.5, 1.0, 0.5]
  ];

  let canvasHolder = document.getElementById(documentElement);

  canvasHolder.addEventListener('mousedown', function (e) {
    mouseDown = true;
    let x = (e.clientX - canvasHolder.offsetLeft) + document.body.scrollLeft + document.documentElement.scrollLeft;
    let y = (e.clientY - canvasHolder.offsetTop) + document.body.scrollTop + document.documentElement.scrollTop;
    waterModel.touchWater(x, y, 1.5, mouseDown ? finger : pixel);
  }, false);

  canvasHolder.addEventListener('mouseup', function (e) {
    mouseDown = false;
  }, false);

  canvasHolder.addEventListener('mousemove', function (e) {
    let x = (e.clientX - canvasHolder.offsetLeft) + document.body.scrollLeft + document.documentElement.scrollLeft;
    let y = (e.clientY - canvasHolder.offsetTop) + document.body.scrollTop + document.documentElement.scrollTop;
    // mozPressure: https://developer.mozilla.org/en/DOM/Event/UIEvent/MouseEvent
    waterModel.touchWater(x, y, 1.5, mouseDown ? finger : pixel);
  }, false);
}

/**
 * Creates a canvas with a radial gradient from white in the center to black on the outside.
 */
export function createRadialCanvas(width, height) {
  // Create a canvas
  let pointerCanvas = document.createElement('canvas');
  pointerCanvas.setAttribute('width', width);
  pointerCanvas.setAttribute('height', height);
  let pointerCtx = pointerCanvas.getContext('2d');

  // Create a drawing on the canvas
  let radgrad = pointerCtx.createRadialGradient(width / 2, height / 2, 0, width / 2, height / 2, height / 2);
  radgrad.addColorStop(0, '#fff');
  radgrad.addColorStop(1, '#000');

  pointerCtx.fillStyle = radgrad;
  pointerCtx.fillRect(0, 0, width, height);

  return pointerCanvas;
}

/**	
 * Creates a 2D pointer array from a given canvas with a grayscale image on it. 
 * This canvas image is then converted to a 2D array with values between -1.0 and 0.0.
 * 
 * Example:
 * 	var array2d = [
 * 		[0.5, 1.0, 0.5], 
 * 		[1.0, 1.0, 1.0], 
 * 		[0.5, 1.0, 0.5]
 * 	];
 */
export function create2DArray(canvas) {
  let width = canvas.width;
  let height = canvas.height;

  // Create an empty 2D  array
  let pointerArray = new Array(width);
  for (let x = 0; x < width; x++) {
    pointerArray[x] = new Array(height);
    for (let y = 0; y < height; y++) {
      pointerArray[x][y] = 0.0;
    }
  }

  // Convert gray scale canvas to 2D array
  let pointerCtx = canvas.getContext('2d');
  let imgData = pointerCtx.getImageData(0, 0, width, height);
  let pixels = imgData.data;
  let n = pixels.length;
  for (let i = 0; i < n; i += 4) {
    // Get the pixel from input
    let pixVal = pixels[i];// only use red
    let arrVal = pixVal / 255.0;

    let pixel = i / 4;
    let x = pixel % width;
    let y = (pixel - x) / width;

    pointerArray[x][y] = arrVal;
  }

  return pointerArray;
}

window.requestAnimFrame = (function () {
  return window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function (/* function */ callback, /* DOMElement */ element) {
      window.setTimeout(callback, 1000 / 60);
    };
})();
