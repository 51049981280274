import './App.css';
import 'firebase/auth';
import 'firebase/firestore';

import { useDarkMode } from '@hooks/useDarkMode.js';
import { amber, cyan } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import rootReducer from '@reducers/index.js';
import * as firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { applyMiddleware, createStore } from 'redux';
import { createFirestoreInstance } from 'redux-firestore';
import thunk from 'redux-thunk';

import initFirebase from './initFirebase.js';
import Routes from './routes';

const lightTheme = createMuiTheme({
  palette: {
    background: {
      default: '#f6f6f6',
    },
    primary: {
      light: cyan[50],
      main: cyan[500]
    },
    secondary: {
      light: amber[50],
      main: amber[800]
    },
    type: 'light',

  },
  typography: {
    // Use the system font instead of the default Roboto font.
    button: {
      padding: '0.2rem',
    },
    fontFamily: [
      '"Lato"',
      'sans-serif'
    ].join(','),
  }
});

const darkTheme = createMuiTheme({
  palette: {
    primary: {
      main: cyan[500]
    },
    secondary: {
      main: amber[800]
    },
    type: 'dark',
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    button: {
      padding: '0.5rem',
    },
    fontFamily: [
      '"Lato"',
      'sans-serif'
    ].join(','),
  }
});


export default function App(props) {
  const { cleaningUp, setCleaningUp } = props;
  const [firebaseConfig, setFirebaseConfig] = useState({});

  useEffect(() => {
    if (firebase.apps.length > 0 || cleaningUp) return;
    const cleanUp = initFirebase({ project: props.project, setCleaningUp });

    const initialState = {};
    const middleware = [thunk];
    const store = createStore(rootReducer, initialState, applyMiddleware(...middleware));

    const rrfConfig = {
      presence: 'presence',
      sessions: 'sessions', // where list of online users is stored in database
      useFirestoreForProfile: true, // where list of user sessions is stored in database (presence must be enabled)
      userProfile: 'users' // Firestore for Profile instead of Realtime DB
    };

    const rrfProps = {
      config: rrfConfig,
      createFirestoreInstance,
      dispatch: store.dispatch,
      firebase // <- needed if using firestore
    };

    setFirebaseConfig({
      rrfProps, store
    });

    return cleanUp;
  }, [props.project, cleaningUp]);

  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  return (
    <>
      {
        firebaseConfig.store != undefined
          ?
          (
            <Provider store={firebaseConfig.store}>
              <ReactReduxFirebaseProvider {...firebaseConfig.rrfProps}>
                <ThemeProvider theme={themeMode}>
                  <CssBaseline />
                  <Routes toggleTheme={toggleTheme} />
                </ThemeProvider>
              </ReactReduxFirebaseProvider>
            </Provider>
          )
          :
          'initializing app'
      }
    </>
  );
}
