/**                                                                         
 _|_|_|_|                              _|_|_|_|_|  _|    _|      _|            
 _|        _|  _|_|    _|_|      _|_|      _|          _|_|_|_|  _|    _|_|    
 _|_|_|    _|_|      _|_|_|_|  _|_|_|_|    _|      _|    _|      _|  _|_|_|_|  
 _|        _|        _|        _|          _|      _|    _|      _|  _|        
 _|        _|          _|_|_|    _|_|_|    _|      _|      _|_|  _|    _|_|_|  
 * FreeTitle Dev
 * Author: Craig P.
**/

import {ChildrenProps, LoadState} from '@commonTypes/reactTypes';
import {FirebaseFirestore} from '@firebase/firestore-types';
import { useDocument } from '@hooks/useDocument';
import * as firebase from 'firebase/app';
import React from 'react';
import {useFirestore} from 'react-redux-firebase';

type DocumentContext<T = firebase.firestore.DocumentData> = {
  dataLoadState: LoadState,
  documentData: undefined | T,
  documentRef: undefined | firebase.firestore.DocumentReference<T>, 
  setDocumentRef: React.Dispatch<React.SetStateAction<firebase.firestore.DocumentReference<T> | undefined>>,
};

/**
 * Description: 
 * 
 * This is used to create a context that only reads & subscribes to a document. 
 * It basically wraps a context around useDocument to make it accessible from more than one component. 
 * 
 * Usage: 
 * @param getDocumentReference optional
 * Checkout @contexts/BlogDocumentContext.tsx
 */
function createDocumentContext<T = firebase.firestore.DocumentData>(getDocumentReference?: (firestore: FirebaseFirestore) => firebase.firestore.DocumentReference<T>): [
  React.Context<DocumentContext<T>>,
  (props: ChildrenProps) => JSX.Element,
 ] {
  const defaultValue: DocumentContext<T> = {
    dataLoadState: LoadState.loading,
    documentData: undefined,
    documentRef: undefined, 
    setDocumentRef: () => {},
  };
  const DocumentContext = React.createContext<DocumentContext<T>>(defaultValue); 
  const DocumentProvider = (props: ChildrenProps) => {
    const firestore = useFirestore();
    const initialDocumentReference = getDocumentReference && getDocumentReference(firestore);
    const {dataLoadState, documentData, documentRef, setDocumentRef} = useDocument<T>(initialDocumentReference);
  
    return (
      <DocumentContext.Provider value={{
        dataLoadState,
        documentData,
        documentRef, 
        setDocumentRef
      }} >
        {props.children}
      </DocumentContext.Provider>
    );
  };

  return [ DocumentContext, DocumentProvider ];
}

export default createDocumentContext;