/**                                                                         
 _|_|_|_|                              _|_|_|_|_|  _|    _|      _|            
 _|        _|  _|_|    _|_|      _|_|      _|          _|_|_|_|  _|    _|_|    
 _|_|_|    _|_|      _|_|_|_|  _|_|_|_|    _|      _|    _|      _|  _|_|_|_|  
 _|        _|        _|        _|          _|      _|    _|      _|  _|        
 _|        _|          _|_|_|    _|_|_|    _|      _|      _|_|  _|    _|_|_|  
 * FreeTitle Dev
 * Author: Craig P.
 */

import { CircularProgress } from '@material-ui/core';
import React from 'react';

interface Props {
  style?: object,
}
/**
 * Description: 
 * 
 * This shows a spinner to indicate something is loading. Supports custom styles;
 * 
 * Usage: 
 * 
 * @param style optional style
 * 
 * \<Spinner \/\>
 * or
 * \<Spinner style={width: '100px'}\/\>
 */
const Spinner = (props: Props) => {
  const inputStyle = props.style ?? {};
  const defaultStyle = { display: 'flex', justifyContent: 'center' };
  const style = Object.assign(defaultStyle, inputStyle);
  return (<div style={style}><CircularProgress /></div>);
};

export default Spinner;