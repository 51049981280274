import { useAuth } from '@hooks/useAuth.js';
import { Link as MaterialLink } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MaterialMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import StarsIcon from '@material-ui/icons/Stars';
import withStyles from '@material-ui/styles/withStyles';
import React, { Component, useEffect, useState } from 'react';
import {
  isEmpty,
  isLoaded,
  useFirebase,
  useFirestore,
  useFirestoreConnect
} from 'react-redux-firebase';
import { Link, withRouter } from 'react-router-dom';

import HomeCanvas from './HomeCanvas.js';
import Menu from './Menu';
import Search from './Search.js';

const logo = require('../images/trans.svg');


const styles = theme => ({
  appBar: {
    backgroundColor: `${theme.palette.type == 'light' ? 'white' : 'black'}`,
    boxShadow: 'none',
    position: 'sticky',
    // overflowX: 'hidden',
    // overflowY: 'hidden',
  },
  flex: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-evenly'
    }
  },
  grow: {
    flexGrow: 0
  },
  grow_desktop: {
    flexGrow: 1
  },
  hover: {
    '&:hover': {
      color: `${theme.palette.primary.main}`,
      opacity: 1,
    },
  },
  iconButton: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
    float: 'right'
  },
  iconContainer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
    flexGrow: 1
  },
  inline: {
    display: 'inline'
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  productLogo: {
    borderLeft: `1px solid ${theme.palette.grey['A100']}`,
    display: 'inline-block',
    marginLeft: 32,
    paddingLeft: 24,
    [theme.breakpoints.up('md')]: {
      paddingTop: '1.5em'
    }
  },

  searchIcon: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    pointerEvents: 'none',
    position: 'absolute',
    width: theme.spacing(7)
  },
  tabContainer: {
    marginLeft: 32,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  tabItem: {
    // paddingTop: 20,
    // paddingBottom: 20,
    minWidth: 'auto'
  },
  tagline: {
    display: 'inline-block',
    marginLeft: 10
  }
});

function Topbar(props) {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const [auth, authData] = useAuth();

  const theme = useTheme();

  const [value, setvalue] = useState(0);
  const [menuDrawer, setmenuDrawer] = useState(false);
  const [avatar, setavatar] = useState(auth.photoURL);
  const [editor, seteditor] = useState(null);
  const [admin, setadmin] = useState(null);
  const [loaded, setloaded] = useState(false);
  const [messageCount, setmessageCount] = useState(0);
  const [coins, setcoins] = useState(0);

  const toPublicationUpload = () => {
    props.history.push({
      pathname: 'publicationupload'
    });
  };

  useEffect(() => {
    if (authData) {
      if (authData.avatarUrl) {
        setavatar(authData.avatarUrl);
      }
      if (authData.messages) {
        let messageCount = authData.messages && authData.messages.reduce((prev, cur) => {
          return prev + (parseInt(cur.count || 1));
        }, 0) || 0;
        setmessageCount(messageCount);
      }
      setcoins(authData.coins || 0);
      seteditor(!!authData.editor);
      setadmin(!!authData.admin);
      setloaded(true);
    }
  }, [authData]);

  const handleChange = (event, value) => {
    setvalue(value);
  };

  const mobileMenuOpen = event => {
    setmenuDrawer(true);
  };

  const mobileMenuClose = event => {
    setmenuDrawer(false);
  };

  const current = () => {
    const path = window.location.pathname;
    if (path == '/') {
      return 0;
    }
    if (path == '/event') {
      return 0;
    }
    if (path == '/publications') {
      return 1;
    }
    if (path == '/rssmanager') {
      return 2;
    }
    if (path == '/blogs') {
      return 3;
    }
    if (path == '/missions') {
      return 4;
    }
    if (path == '/aboutus') {
      return 5;
    }
    if (path == '/create') {
      return false;
    }
    if (path == '/messages') {
      return 7;
    }
    if (path == '/coins') {
      return 8;
    }
    if (path == '/login') {
      return false;
    }
    if (window.location.href.endsWith('/login')) {
      return false;
    }
    return false;
  };

  const signout = () => {
    firebase
      .auth()
      .signOut()
      .then(
        function () {
          console.log('signed out');
          props.history.push({
            pathname: '/'
          });
        },
        function (error) {
          console.log('sign out error');
        }
      );
  };

  const { classes } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getBlog = (id) => {
    props.history.push({
      pathname: '/',
      search: '?redirect=blogdetail&id=' + id
    });
  };

  const getUser = (id) => {
    props.history.push({
      pathname: '/',
      search: '?redirect=profile&id=' + id
    });
  };

  const getMission = (id) => {
    props.history.push({
      pathname: '/',
      search: '?redirect=missiondetail&id=' + id
    });
  };

  return (
    <AppBar className={classes.appBar} color='default' id='navbar' position='absolute'>
      <div style={{ position: 'absolute' }}>
        {/* <HomeCanvas image={theme.palette.type == "dark" 
				? "https://firebasestorage.googleapis.com/v0/b/freetitle.appspot.com/o/background_bw_30_invert.png?alt=media&token=1520649d-e4c6-483c-8d69-2ce910689897"
				: "https://firebasestorage.googleapis.com/v0/b/freetitle.appspot.com/o/background_bw_30.png?alt=media&token=dda9376c-31ec-485a-bc69-28ba4aa8e4b1" 
				}
				/> */}
        <HomeCanvas image={theme.palette.type == 'dark'
          ? 'https://firebasestorage.googleapis.com/v0/b/freetitle.appspot.com/o/grey.png?alt=media&token=295712d1-7277-42d3-983a-8e85090480ab'
          : 'https://firebasestorage.googleapis.com/v0/b/freetitle.appspot.com/o/white.png?alt=media&token=c8a51b20-470a-4af0-b993-f74f31506e16'
        }
        />
      </div>

      <Toolbar style={{ minHeight: 'auto' }}>
        <Grid item className={classes.flex} xs={12}>
          <div className={classes.inline}>
            <Typography noWrap color='inherit' style={{ alignItems: 'center', display: 'flex' }} variant='h6'>
              <img alt='' src={logo} width={30} />
              <Link className={classes.link} to='/'>

                <span className={classes.tagline}>FreeTitle</span>
              </Link>
            </Typography>
          </div>
          <div className={classes.productLogo}>
            <Typography></Typography>
          </div>

          <IconButton
            className={classes.iconButton}
            color='inherit'
            style={{ width: 'fit-content' }}
            onClick={props.toggleTheme}
          >
            {theme.palette.type == 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
          </IconButton>

          <Search getBlog={getBlog} getMission={getMission} getUser={getUser} />
          <div className={classes.grow_desktop} />

          <div className={classes.iconContainer}>
            <IconButton
              aria-label='Menu'
              className={classes.iconButton}
              color='inherit'
              style={{ width: 'fit-content' }}
              onClick={mobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
          </div>

          {!props.noTabs && (
            <React.Fragment>
              <div className={classes.tabContainer}>
                <Tabs
                  indicatorColor='primary'
                  textColor='primary'
                  value={current()}
                  onChange={handleChange}
                >
                  {Menu.map((item, index) => (
                    <Tab
                      key={index + 1}
                      className={classes.hover}
                      classes={{ root: classes.tabItem }}
                      component={item.external ? MaterialLink : Link}
                      href={item.external ? item.pathname : null}
                      label={item.label}
                      to={
                        item.external
                          ? null
                          : { pathname: item.pathname }
                      }
                    />
                  ))}
                  {isLoaded(auth) && !isEmpty(auth) && loaded && (
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                      <Button
                        aria-controls="fade-menu"
                        aria-haspopup="true"
                        color="primary"
                        size="large"
                        style={{ margin: 'auto' }}
                        variant="contained"
                        onClick={handleClick}
                      >
                        CREATE
                                            </Button>

                      <MaterialMenu
                        keepMounted
                        anchorEl={anchorEl}
                        id="simple-menu"
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem style={{ display: 'flex' }} onClick={handleClose} >
                          <Button style={{ margin: 'auto' }} onClick={() => { props.history.push('/blogupload'); }}>CREATE BLOG</Button>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                          <Button style={{ margin: 'auto' }} onClick={() => { props.history.push('/missionupload'); }}>CREATE MISSION</Button>
                        </MenuItem>
                        {(editor || admin) && <MenuItem onClick={handleClose}>
                          <Button color="primary" style={{ margin: 'auto' }} onClick={toPublicationUpload}>PUBLICATION</Button>
                        </MenuItem>}
                      </MaterialMenu>
                    </div>

                  )}
                  <Tab
                    key={'messages'}
                    className={classes.hover}
                    classes={{ root: classes.tabItem }}
                    component={Link}
                    href={null}
                    label={
                      isLoaded(auth) && !isEmpty(auth) ? (
                        loaded ? (
                          <Badge badgeContent={messageCount} color="secondary">
                            <MailIcon />
                          </Badge>
                        ) : (
                            <Badge badgeContent={messageCount} color="secondary">
                              <MailIcon />
                            </Badge>
                          )
                      ) : (
                          <Badge badgeContent={messageCount} color="secondary">
                            <MailIcon />
                          </Badge>
                        )
                    }
                    to={'/messages'}
                  ></Tab>

                  <Tab
                    key={'coins'}
                    classes={{ root: classes.tabItem }}
                    component={Link}
                    href={null}
                    label={
                      <Badge badgeContent={coins} color="secondary" style={{ color: 'goldenrod' }}>
                        <StarsIcon />
                      </Badge>
                    }
                    to={'/coins'}
                  ></Tab>

                  <Tab
                    key={'login'}
                    className={classes.hover}
                    classes={{ root: classes.tabItem }}
                    component={Link}
                    href={null}
                    label={
                      isLoaded(auth) && !isEmpty(auth) ? (
                        loaded ? (
                          <div>
                            <Avatar alt={auth.displayName} src={avatar} style={{ margin: '5px' }} />
                            <div onClick={signout}> sign out</div>
                          </div>
                        ) : (
                            <>
                              <Avatar alt={auth.displayName} src={auth.photoURL} style={{ margin: '5px' }} />
                              <div onClick={signout}> sign out</div>
                            </>
                          )
                      ) : (
                          <AccountCircle />
                        )
                    }
                    to={'/login'}
                  ></Tab>
                </Tabs>
              </div>
            </React.Fragment>
          )}
          <div className={classes.grow} />

          {!props.noTabs && (
            <React.Fragment>
              <div className={classes.tabContainer}>
                <SwipeableDrawer
                  anchor='right'
                  open={menuDrawer}
                  onClose={mobileMenuClose}
                  onOpen={mobileMenuOpen}
                >
                  <AppBar title='Menu' />

                  <List>
                    {Menu.map((item, index) => (
                      <ListItem
                        key={item.label}
                        button
                        component={item.external ? MaterialLink : Link}
                        href={item.external ? item.pathname : null}
                        to={
                          item.external
                            ? null
                            : { pathname: item.pathname }
                        }
                      >
                        <ListItemText primary={item.label} />
                      </ListItem>
                    ))}

                    {isLoaded(auth) && !isEmpty(auth) && loaded && (
                      <div style={{ alignItems: 'center', display: 'flex' }}>
                        <Button
                          aria-controls="fade-menu"
                          aria-haspopup="true"
                          color="primary"
                          style={{ margin: 'auto' }}
                          variant="contained"
                          onClick={handleClick}
                        >
                          CREATE
                                                </Button>

                        <MaterialMenu
                          keepMounted
                          anchorEl={anchorEl}
                          id="simple-menu"
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem style={{ display: 'flex' }} onClick={handleClose} >
                            <Button style={{ margin: 'auto' }} onClick={() => {
                              props.history.push({
                                pathname: '/blogupload',
                                state: {
                                  avatar: avatar,
                                  uid: auth.uid
                                }
                              });
                            }}>CREATE BLOG</Button>
                          </MenuItem>
                          <MenuItem onClick={handleClose}>
                            <Button style={{ margin: 'auto' }} onClick={() => {
                              props.history.push({
                                pathname: '/missionupload',
                                state: {
                                  avatar: avatar,
                                  uid: auth.uid
                                }
                              });
                            }
                            }>CREATE MISSION</Button>
                          </MenuItem>
                          {/* {(editor || admin) && <MenuItem onClick={handleClose}>
														<Button color="primary"  style={{margin: "auto"}} onClick={toRSSmanager}>RSS FEED</Button>
													</MenuItem>} */}
                          {(editor || admin) && <MenuItem onClick={handleClose}>
                            <Button color="primary" style={{ margin: 'auto' }} onClick={toPublicationUpload}>PUBLICATION</Button>
                          </MenuItem>}
                        </MaterialMenu>
                      </div>
                    )}

                    <ListItem
                      key={'message'}
                      button
                      component={Link}
                      href={null}
                      to={{ pathname: '/messages' }}
                    >
                      {isLoaded(auth) && !isEmpty(auth) ? (
                        loaded ? (
                          <Badge badgeContent={messageCount} color="secondary">
                            <MailIcon />
                          </Badge>
                        ) : (
                            <Badge badgeContent={messageCount} color="secondary">
                              <MailIcon />
                            </Badge>
                          )
                      ) : (
                          <Badge badgeContent={messageCount} color="secondary">
                            <MailIcon />
                          </Badge>
                        )}
                    </ListItem>

                    <ListItem
                      key={'coins'}
                      button
                      component={Link}
                      href={null}
                      to={{ pathname: '/coins' }}
                    >
                      <Badge badgeContent={coins} color="primary">
                        <StarsIcon />
                      </Badge>
                    </ListItem>

                    <ListItem
                      key={'acount'}
                      button
                      component={Link}
                      href={null}
                      to={{ pathname: '/login', search: props.location.search }}
                    >
                      {isLoaded(auth) && !isEmpty(auth) ? (
                        loaded ? (
                          <>
                            <Avatar alt={auth.displayName} src={avatar} style={{ margin: '5px' }} />
                            <div onClick={signout}> sign out</div>
                          </>
                        ) : (
                            <>
                              <Avatar alt={auth.displayName} src={auth.photoURL} style={{ margin: '5px' }} />
                              <div onClick={signout}> sign out</div>
                            </>
                          )
                      ) : (
                          <AccountCircle />
                        )}
                    </ListItem>
                  </List>
                </SwipeableDrawer>
              </div>
            </React.Fragment>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default withRouter(withStyles(styles)(Topbar));
