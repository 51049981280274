import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';

import { useUser } from './useUser';

/*
    This hook returns data for currently logged-in user.
    auth contains info for authentication stored in firebase-auth (i.e. auth.uid...)
    userData contains user data stored in firestore/users (i.e. auth.displayName, auth.avatarUrl, auth.backgrounUrl...)
*/
export const useAuth = () => {
  const auth = useSelector(state => state.firebase.auth);
  const { userData, setuid } = useUser();

  useEffect(() => {
    if (isLoaded(auth) && !isEmpty(auth)) {
      setuid(auth.uid);
    }
  }, [auth]);

  return [auth, userData];
};