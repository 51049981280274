import './index.css';

import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import useProject from './useProject.js';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

function RootApp() {
  const [project, toggleProject] = useProject();
  const [cleaningUp, setCleaningUp] = useState(false);
  const onToggle = () => {
    setCleaningUp(true);
    toggleProject();
  };

  if (cleaningUp) {
    return 'changing firebase project';
  }

  if (!isLocalhost) {
    return (
      <App key='freetitle' project='freetitle' />
    );
  }

  if (project === 'freetitle') {
    return (
      <>
        <Button onClick={onToggle}>[localhost only] change project to freetitletest</Button>
        <App key='freetitle' cleaningUp={cleaningUp} project='freetitle' setCleaningUp={setCleaningUp} />
      </>
    );
  }

  return (
    <>
      <Button onClick={onToggle}>[localhost only] change project to freetitle</Button>
      <App key='freetitletest' cleaningUp={cleaningUp} project='freetitletest' setCleaningUp={setCleaningUp} />
    </>
  );
}
ReactDOM.render(<RootApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
