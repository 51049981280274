import React, { useEffect, useRef, useState } from 'react';

import { enableMouseInteraction, RainMaker, WaterModel } from './WaterRipple.js';

export default function HomeCanvas(props) {

  useEffect(() => {
    if (document.querySelector('#navbar')) {
      setheight(document.querySelector('#navbar').offsetHeight);
    }
  }, [document.querySelector('#navbar') && document.querySelector('#navbar').offsetHeight]);

  const [width, _setwidth] = useState(props.width || window.innerWidth);
  const [height, _setheight] = useState(props.height || 50);
  // https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
  const widthRef = useRef(width);
  const setwidth = data => {
    widthRef.current = data;
    _setwidth(data);
  };
  const setheight = data => {
    widthRef.current = data;
    _setheight(data);
  };

  useEffect(() => {
    let resizeListener = window.addEventListener('resize', () => {
      if (window.innerWidth != widthRef.current) {
        setwidth(window.innerWidth);
      }
    });
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const [image, setimage] = useState(props.image);

  useEffect(() => {
    setimage(props.image);
  }, [props.image]);

  useEffect(() => {
    if (width * height > 500 * 60) {
      console.log('screen size too large, animation disabled');
      let waterHolder = document.querySelector('#waterHolder');
      waterHolder.innerHTML = '';
      return;
    }
    let waterHolder = document.querySelector('#waterHolder');
    waterHolder.innerHTML = null;
    let waterModel = new WaterModel(width, height, {
      clipping: 5,
      damping: 0.985,
      evolveThreshold: 0.05,
      interpolate: false,
      maxFps: 30,
      resolution: 2.0,
      showStats: false,
    });
    enableMouseInteraction(waterModel, 'waterHolder');
    let rain = new RainMaker(width, height, waterModel, [
      [0.5, 1.0, 0.5],
      [1.0, 1.0, 1.0],
      [0.5, 1.0, 0.5]
    ]);
    rain.setRaindropsPerSecond(2);
    rain.setRainMinPressure(1.0);
    rain.setRainMaxPressure(3.0);
    let finger = [
      [0.5, 1.0, 0.5],
      [1.0, 1.0, 1.0],
      [0.5, 1.0, 0.5]
    ];

    waterModel.touchWater(100, 100, 1.5, finger);
  }, [width, height, image]);

  return (<div>
    <div id="waterHolder"></div>
  </div>);
}