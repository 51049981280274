/**                                                                         
 _|_|_|_|                              _|_|_|_|_|  _|    _|      _|            
 _|        _|  _|_|    _|_|      _|_|      _|          _|_|_|_|  _|    _|_|    
 _|_|_|    _|_|      _|_|_|_|  _|_|_|_|    _|      _|    _|      _|  _|_|_|_|  
 _|        _|        _|        _|          _|      _|    _|      _|  _|        
 _|        _|          _|_|_|    _|_|_|    _|      _|      _|_|  _|    _|_|_|  
 * FreeTitle Dev
 * Author: Craig P.
 **/

import { LoadState } from '@commonTypes/reactTypes';
import { useCallback, useEffect, useState } from 'react';

import useIsMounted from './useIsMounted';

/**
 * Description: 
 * 
 * A custom hook to easily get and subscribe to a single firebase document reference. 
 * Returns the data and the load state. 
 * More complex firebase references (i.e. collectionReference) is not supported. 
 * Writing to the database is not done using this hook, and should be done by calling firebase functions. 
 * 
 * Usage: 
 * 
 * @param initialDocumentRef
 * const documentRef = firestore.collection('employees').doc(EMPLOYEE_ID);
 * 
 * const {dataLoadState, documentData} = useDocument(documentRef);
 * 
 * // or you can initialize with no reference and set it once you have it. 
 * 
 * const {id} = props;
 * const {dataLoadState, documentData, documentRef, setDocumentRef} = useDocument(initialDocumentRef);
 * useEffect(() => {
 *  if (id !== null){
 *    setDocumentRef(firestore.collection('employees').doc(id));
 *  }
 * }, [id])
 */
export const useDocument = <T>(initialDocumentRef?: firebase.firestore.DocumentReference<T>) => {
  const [documentRef, setDocumentRef] = useState<firebase.firestore.DocumentReference<T> | undefined>(initialDocumentRef);
  const [documentData, setDocumentData] = useState<T>();
  const [dataLoadState, setDataLoadState] = useState<LoadState>(LoadState.loading);
  const isMounted = useIsMounted();
  
  const handleSnapshot = (doc: firebase.firestore.DocumentSnapshot<T>): void => {
    const data = doc.data();
    if (data == null) {
      return;
    }
    if (isMounted()){
      setDocumentData(data);
    }
    return;
  };

  const getDocument = useCallback(async (documentRef) => {
    setDataLoadState(LoadState.loading);
    await documentRef.get().then(handleSnapshot)
      .catch(() => {
        if (isMounted()){
          setDataLoadState(LoadState.error);
        }
      })
      .then(() => {
        if (isMounted()){
          setDataLoadState(LoadState.loaded);
        }
      });
  }, []);

  const listenForDocumentChange = useCallback((documentRef) => {
    const unsubscribe = documentRef.onSnapshot(handleSnapshot);
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (documentRef === undefined){
      return;
    }
    getDocument(documentRef);
    const unsubscribe = listenForDocumentChange(documentRef);
    return unsubscribe;
  }, [documentRef]);

  return { dataLoadState, documentData, documentRef, setDocumentRef };
};