import FTErrorBoundary from '@components/common/FTErrorBoundary';
import Spinner from '@components/common/Spinner';
import Topbar from '@components/Topbar.js';
import { BlogDocumentProvider } from '@contexts/BlogDocumentContext';
import { BlogsCollectionProvider } from '@contexts/BlogsCollectionContext';
// import { BlogsProvider } from '@contexts/BlogsContext.js';
import { MissionsProvider } from '@contexts/MissionsContext.js';
import history from '@src/FThistory';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded, useFirestore } from 'react-redux-firebase';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
const AboutUs = React.lazy(() => import('@components/AboutUs.js'));
const BlogDetail = React.lazy(() => import('@components/BlogDetail.js'));
const BlogList = React.lazy(() => import('@components/BlogList.js'));
const BlogUpload = React.lazy(() => import('@components/BlogUpload.js'));
const Coins = React.lazy(() => import('@components/Coins.js'));
const PDF_reader = React.lazy(() => import('@components/helpers/PDF_reader.js'));
const Home = React.lazy(() => import('@components/Home'));
const Login = React.lazy(() => import('@components/Login.js'));
const Messages = React.lazy(() => import('@components/Messages.js'));
const MissionDetail = React.lazy(() => import('@components/MissionDetail.js'));
const MissionList = React.lazy(() => import('@components/MissionList.js'));
const MissionUpload = React.lazy(() => import('@components/MissionUpload.js'));
const Profile = React.lazy(() => import('@components/Profile.js'));
const ProfileUpload = React.lazy(() => import('@components/ProfileUpload.js'));
const PublicationDetail = React.lazy(() => import('@components/PublicationDetail.js'));
const PublicationList = React.lazy(() => import('@components/PublicationList.js'));
const PublicationUpload = React.lazy(() => import('@components/PublicationUpload.js'));
const RSSmanager = React.lazy(() => import('@components/RSSmanager.js'));
const Search = React.lazy(() => import('@components/Search.js'));

function PrivateRoute(args) {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) {
    return <Spinner />;
  }
  return (!isEmpty(auth)
    ?
    <Route {...args} />
    :
    <Route
      render={() => {
        return (<Redirect
          to={{
            pathname: '/login',
          }}
        />);
      }}
    />);
}

function RedirectRoute() {
  const str = window.location.search;
  const urlParams = new URLSearchParams(str);
  const redirect = urlParams.get('redirect');
  const id = urlParams.get('id');
  const auth = useSelector(state => state.firebase.auth);
  if (!redirect) {
    return <Route exact path='/' render={props => (<FTErrorBoundary><Home {...props} /></FTErrorBoundary>)} />;
  } else {
    if (redirect == 'profile') {
      if (id) {
        return (<Route
          render={() => (
            <Redirect
              to={{
                pathname: '/' + redirect,
                search: id ? ('?id=' + id) : null,
              }}
            />
          )
          }
        />);
      } else {
        return (isLoaded(auth) && !isEmpty(auth) ? <Route exact path="/profile" render={props => (<FTErrorBoundary><Profile {...props} /></FTErrorBoundary>)} /> :
          <Route
            render={() => (
              <Redirect
                to={{
                  pathname: '/login',
                }}
              />
            )
            }
          />);
      }
    }
    if (['login', 'profileupload', 'missionupload', 'blogupload', 'rssmanager', 'publicationupload', 'messages', 'coins'].includes(redirect)) {
      return (<PrivateRoute
        render={() => (
          <Redirect
            to={{
              pathname: '/' + redirect,
            }}
          />
        )
        }
      />);
    }
    return (<Route
      render={() => (
        <Redirect
          to={{
            pathname: '/' + redirect,
            search: id ? '?id=' + id : null,
          }}
        />
      )
      }
    />);
  }

}

export default props => {

  const Provider = (props) => {
    return (
      <BlogsCollectionProvider>
        <MissionsProvider>
          <BlogDocumentProvider>
            {props.children}
          </BlogDocumentProvider>
        </MissionsProvider>
      </BlogsCollectionProvider>
    );
  };

  return (
    <BrowserRouter history={history}>
      <Provider>
        <Topbar currentPath='/' toggleTheme={props.toggleTheme} />
        <Suspense fallback={<Spinner />}>
          <Switch>
            <RedirectRoute key='/' exact path='/' />
            <PrivateRoute key='blogupload' path="/blogupload" render={props => (<FTErrorBoundary><BlogUpload {...props} /></FTErrorBoundary>)} />
            <PrivateRoute key='missionupload' path="/missionupload" render={props => (<FTErrorBoundary><MissionUpload {...props} /></FTErrorBoundary>)} />
            <PrivateRoute key='profileupload' path="/profileupload" render={props => (<FTErrorBoundary><ProfileUpload {...props} /></FTErrorBoundary>)} />
            <PrivateRoute key='rssmanager' path="/rssmanager" render={props => (<FTErrorBoundary><RSSmanager {...props} /></FTErrorBoundary>)} />
            <PrivateRoute key='publicationupload' path="/publicationupload" render={props => (<FTErrorBoundary><PublicationUpload {...props} /></FTErrorBoundary>)} />
            <Route key='profile' exact path="/profile" render={props => (<FTErrorBoundary><Profile {...props} /></FTErrorBoundary>)} />
            <Route key='login' exact path='/login' render={props => (<FTErrorBoundary><Login {...props} /></FTErrorBoundary>)} />
            <Route key='blogs' exact path='/blogs' render={props => (<FTErrorBoundary><BlogList {...props} /></FTErrorBoundary>)} />
            <Route key='blogdetail' exact path='/blogdetail' render={props => (<FTErrorBoundary><BlogDetail {...props} /></FTErrorBoundary>)} />
            <Route key='missions' exact path='/missions' render={props => (<FTErrorBoundary><MissionList {...props} /></FTErrorBoundary>)} />
            <Route key='missiondetail' exact path='/missiondetail' render={props => (<FTErrorBoundary><MissionDetail {...props} /></FTErrorBoundary>)} />
            <Route key='search' exact path='/search' render={props => (<FTErrorBoundary><Search {...props} /></FTErrorBoundary>)} />
            <PrivateRoute key='messages' exact path='/messages' render={props => (<FTErrorBoundary><Messages {...props} /></FTErrorBoundary>)} />
            <PrivateRoute key='coins' exact path='/coins' render={props => (<FTErrorBoundary><Coins {...props} /></FTErrorBoundary>)} />
            <Route key='publications' exact path='/publications' render={props => (<FTErrorBoundary><PublicationList {...props} /></FTErrorBoundary>)} />
            <Route key='publicationdetail' exact path='/publicationdetail' render={props => (<FTErrorBoundary><PublicationDetail {...props} /></FTErrorBoundary>)} />
            <Route key='pdf' exact path='/pdf' render={props => (<FTErrorBoundary><PDF_reader {...props} /></FTErrorBoundary>)} />
            <Route key='aboutus' exact path='/aboutus' render={props => (<FTErrorBoundary><AboutUs {...props} /></FTErrorBoundary>)} />

          </Switch>
        </Suspense>
      </Provider>
    </BrowserRouter>
  );
};
