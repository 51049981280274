const Menu = [
  {
    label: 'Home',
    pathname: '/'
  },
  {
    label: 'Publications',
    pathname: '/publications'
  },
  {
    label: 'RSS Feed',
    pathname: '/rssmanager'
  },
  {
    label: 'Blogs',
    pathname: '/blogs'
  },
  {
    label: 'Missions',
    pathname: '/missions'
  },
  {
    label: 'About Us',
    pathname: '/aboutus'
  },


];

export default Menu;
