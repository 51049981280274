// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/analytics';
import 'firebase/functions';

import * as firebase from 'firebase/app';

export default function initFirebase(props) {
  const { setCleaningUp, project } = props;
  const firebaseConfig = {
    apiKey: 'AIzaSyDDlwKl-nTIla5EG3_BzaV_Z_7IvQm53Zs',
    appId: '1:813260600443:web:8cfef77c26a36d82465ede',
    authDomain: 'freetitle.firebaseapp.com',
    databaseURL: 'https://freetitle.firebaseio.com',
    measurementId: 'G-20ZNBT2131',
    messagingSenderId: '813260600443',
    projectId: 'freetitle',
    storageBucket: 'freetitle.appspot.com'
  };

  const testFirebaseConfig = {
    apiKey: 'AIzaSyBO0rtszsqgWf5_B8hcpYxan3raUohUCPU',
    appId: '1:850194024895:web:d7392851983bc0eabbb90a',
    authDomain: 'freetitletest.firebaseapp.com',
    databaseURL: 'https://freetitletest.firebaseio.com',
    measurementId: 'G-WWZEH0TLPX',
    messagingSenderId: '850194024895',
    projectId: 'freetitletest',
    storageBucket: 'freetitletest.appspot.com'
  };

  const config = project === 'freetitle' ? firebaseConfig : testFirebaseConfig;

  // Initialize Firebase
  if (firebase.apps.length !== 0) {
    alert('firebase app didnt unload successfully. This happens occasionally. If site crashed, try refresh.');
  }


  const app = firebase.initializeApp(config);

  const cleanUp = async () => {
    await app.delete().then(() => {
      setCleaningUp(false);
    });
  };

  return cleanUp;
}
